<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="getTgameBetList"><i class="fa fa-refresh"></i></div>
            <!--파트너 선택-->
            <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>
            <el-input type="text" size="mini" v-model="tgame365Bet.nickname" placeholder="닉네임을 입력하세요" style="width: 150px;margin-left: 10px"></el-input>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="getTgameBetList">검색
            </el-button>
            <el-select v-model="tgame365Bet.betResult" @change="getTgameBetList" size="mini"
                       placeholder="결과선택"
                       style="width:100px;margin-left: 5px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="당첨" :value="agentConst.SportsBet.BET_RESULT_WIN">당첨</el-option>
                <el-option label="낙첨" :value="agentConst.SportsBet.BET_RESULT_LOSE">낙첨</el-option>
            </el-select>
            <el-select v-model="tgame365Bet.kind" @change="getTgameBetList" size="mini"
                       placeholder="게임선택"
                       style="width:120px;margin-left: 5px">
                <el-option label="전체" :value="null">전체</el-option>
<!--                <el-option label="카지노" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_CASINO">카지노</el-option>-->
<!--                <el-option label="슬롯" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_SLOT">슬롯</el-option>-->
                <el-option label="하이로5초" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_HILOW_5S">하이로 5초</el-option>
                <el-option label="하이로" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_HILOW">하이로10초</el-option>
                <el-option label="룰렛" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_RUOLLET">룰렛</el-option>
<!--                <el-option label="토큰휠" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_FW">토큰휠</el-option>-->
<!--                <el-option label="달팽이 3줄" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_SNAIL3">달팽이 3줄</el-option>-->
<!--                <el-option label="달팽이 4줄" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_SNAIL4">달팽이 4줄</el-option>-->
                <!--                        <el-option label="Fish입출금" :value="agentConst.Tgame365.LEISURE_KIND_TGAME365_FISH">Fish입출금</el-option>-->
            </el-select>

        </div>

        <div class="data">
            <div style="padding: 5px 0;font-size: 14px;width: 100%">
                <span style="padding-left: 20px;">총건수:{{totalBetCount|comma}}건</span>
                <span style="padding-left: 20px;">총베팅금액:{{totalBetCash|comma}}원 </span>
                <span style="padding-left: 20px;">당첨금액:{{totalBetWinCash|comma}} 원 </span>
                <span style="padding-left: 20px;">손이익:{{(totalBetWinCash - totalBetCash)|comma}} 원 </span>
            </div>
            <el-table
                    :data="tgame365BetList"
                    style="width: 100%"
                    max-height="750"
                    border>
                <el-table-column
                        fixed
                        label="닉네임"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.nickname}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="트랜젝션 ID"
                        width="280">
                    <template slot-scope="scope">
                        {{scope.row.transactionid}}
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="회차"
                        width="280">
                    <template slot-scope="scope">
                        {{scope.row.ground}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="gtype"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.gtype}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.betType}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅금액"
                        width="100">
                    <template slot-scope="scope">
                        <span :class="{'text-blue':scope.row.amount>100000}">{{scope.row.amount | comma}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.betType}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅결과"
                        width="70">
                    <template slot-scope="scope">
                        <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_WIN" class="badge badge-primary">당첨</span>
                        <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_LOSE" class="">낙첨</span>
                        <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_CANCEL" class="badge badge-danger">취소</span>
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="베팅시간"
                        width="125">
                    <template slot-scope="scope">
                        {{scope.row.createTime | datef('MM월DD일 HH:mm:ss')}}
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="정산"
                        width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.givebacked == agentConst.YES">완료</span>
                    </template>
                </el-table-column>
            </el-table>


        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="50"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import {Loading} from "element-ui";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import {agentMixin} from "../../common/agent/agentMixin";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {getTgameBetlist2} from "../../network/agent/commonRequest";
    export default {
        name: "AgentTgame365Bet",
        mixins: [agentMixin],
        components: {
            PartnerSubSelectorComp,
            DateSelectorComp,
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                tgame365Bet: {partnerId: null,kind:null,betResult:null},
                tgame365BetList: [],
                pageNum: 1,
                pageSize: 30,
                pageTotal: 0,
                totalBetCount: 0,
                totalBetCash: 0,
                totalBetWinCash: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }]
                },

            }
        },
        methods: {
            getTgameBetList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.tgame365Bet.beginTime = this.startDate
                this.tgame365Bet.endTime = this.endDate
                getTgameBetlist2(this.tgame365Bet, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.data.pageTotal;
                    this.tgame365BetList = res.data.data.list;
                    this.totalBetCount = res.data.data.totalcount;
                    this.totalBetCash = res.data.data.betTotal;
                    this.totalBetWinCash = res.data.data.winTotal;
                    loadingInstance.close();
                })

            },
            changeGame1(){
              this.getTgameBetList()
            },
            changeGame2(){
                this.tgame365Bet.betType=null
                this.getTgameBetList()
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getTgameBetList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getTgameBetList();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            setPartnerId(parterId) {
                this.tgame365Bet.partnerId = parterId
                this.getTgameBetList();
            },
        },
        created() {
            //this.startDate = null
            //this.endDate = null
            //this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
            this.startDate = this.$moment().format('yyyy-MM-DD')
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.getTgameBetList()
        },
    }
</script>

<style scoped>

</style>